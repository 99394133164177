<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="../../assets/conteudos_violacoes_direito_background_02.png"
        alt=""
      />

      <GoBack />
    </div>
    <div class="container position--relative">
      <section class="container">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              style="color: white"
              class="conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <v-col class="d-flex justify-center align-center">
            <img
              width="50px"
              src="../../assets/conteudos_violacoes_direito_title_engine.png"
              alt=""
            />
            <h3 class="ml-3">Descumprimento de prazos</h3>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  O Termo de Acordo e Ajustamento de Conduta (TTAC), assinado em
                  02 de outubro de 2018 no âmbito da Ação Civil Pública nº
                  0400.15.004335-6, fixou as diretrizes para nortear o processo
                  de indenização extrajudicial das pessoas atingidas. Neste
                  documento, dentre outras determinações, foi estabelecido à
                  Fundação Renova — entidade criada para efetivar os programas
                  de reparação em nome das empresas causadoras do rompimento da
                  barragem de Fundão — prazos para iniciar e terminar as
                  negociações no âmbito da Fase de Negociação Extrajudicial
                  (FNE).
                </p>
                <p>
                  O prazo de início das negociações previsto é de três meses, a
                  contar da disponibilização do respectivo
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_sistematizacao_dossie' }"
                    >dossiê</router-link
                  >
                  de cada núcleo familiar à Fundação Renova. Cabendo à entidade,
                  portanto, dentro deste prazo, apresentar uma proposta
                  indenizatória à família, em observância aos danos catalogados
                  durante o processo de cadastramento.
                </p>
                <p>
                  Além deste prazo para apresentação da proposta indenizatória,
                  foi ajustado o prazo limite de um ano para finalização das
                  negociações no âmbito da FNE. Este pode ser prorrogado de
                  acordo com o interesse do(a) atingido(a), a contar, da mesma
                  forma, a partir da disponibilização do dossiê à Fundação
                  Renova.
                </p>
                <p>
                  Foi acordado também que, em caso de descumprimento
                  injustificado destes prazos pela Fundação Renova, poderia ser
                  arbitrada judicialmente multa pelo não cumprimento do TTAC, a
                  ser revertida em favor dos atingidos e atingidas.
                </p>
                <p>
                  Ocorre que, mesmo com a estipulação judicial desses prazos,
                  bem como da possibilidade de aplicação de multa pelo seu
                  descumprimento injustificado, a Fundação Renova desrespeita o
                  acordado de forma reiterada e, por diversas vezes, as famílias
                  atingidas se frustram por não receberem nenhum contato e/ou
                  proposta de indenização dentro do prazo de três meses. De
                  acordo com um levantamento feito pela equipe de Assessoria
                  Jurídica da Cáritas MG, 44,8% dos núcleos familiares
                  assessorados juridicamente receberam a primeira proposta
                  indenizatória
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_ser_atingido_tempos' }"
                    >após o vencimento do prazo</router-link
                  >
                  de três meses.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  Além disso, é preciso salientar que a grande maioria das
                  famílias atingidas que são assessoradas juridicamente recebe a
                  proposta de indenização incompleta, seja dentro ou fora do
                  prazo de três meses,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_desconsideracao_cadastro',
                    }"
                    >não havendo contemplação de todos os danos
                    declarados</router-link
                  >
                  no momento do cadastramento. Nesta circunstância, cabe à
                  família realizar apontamentos em reunião buscando uma nova
                  análise dos pontos faltantes na proposta de indenização. É o
                  caso de 79,29% dos núcleos familiares acompanhados pela
                  Assessoria Jurídica da Cáritas MG.
                </p>
                <p>
                  Cumpre salientar, entretanto, que a Fundação Renova, apesar de
                  manifestar a possibilidade de reavaliar a proposta
                  indenizatória com base no
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_sistematizacao_dossie' }"
                    >dossiê</router-link
                  >, não se compromete a dar um retorno célere às famílias, não
                  estipula uma data para a devolutiva, valendo-se da omissão do
                  TTAC a este respeito. Desta forma, aquelas famílias que optam
                  pela reanálise não têm garantia alguma de quando serão
                  novamente chamadas a negociar, aumentando o sentimento de
                  insegurança e desgaste em busca da
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >.
                </p>
                <p>
                  Ao ser questionada acerca do prazo de resposta sobre a
                  reanálise da proposta, a Fundação Renova apenas se limita a
                  dizer que há a previsão no TTAC do prazo de até um ano para a
                  finalização das negociações. Contudo, o argumento da Fundação
                  Renova não é razoável, uma vez que esse prazo começa a contar
                  a partir do recebimento do dossiê pela entidade e não do
                  início efetivo das negociações na FNE. Importante destacar,
                  inclusive, que em 16% das negociações acompanhadas pela
                  Assessoria Jurídica da Cáritas MG até fevereiro de 2022, houve
                  o descumprimento do prazo limite de um ano previsto no TTAC.
                </p>
                <p>
                  Esses acontecimentos acabam por violar os direitos das
                  famílias atingidas que esperam nessas negociações na FNE o
                  cumprimento do acordado judicialmente pelas empresas
                  causadoras do desastre–crime. O descumprimento desses prazos
                  pela Fundação Renova transgride os direitos dos atingidos e
                  atingidas, causando angústia pela incerteza de uma devolutiva
                  por parte da entidade, bem como revitimiza essas famílias que
                  já se encontram
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_ser_atingido_tempos' }"
                    >aguardando a reparação justa e integral há mais de seis
                    anos</router-link
                  >.
                </p>
                <p>
                  Convida-se à leitura do
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                    class="pink--text"
                    >Parecer Técnico Jurídico<sup>1</sup></a
                  >
                  produzido pela equipe de Assessoria Jurídica da Cáritas MG, em
                  que foram relatadas, com mais detalhes, as práticas e
                  violações da Fundação Renova, no âmbito da FNE, perpetradas a
                  fim de negar a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >
                  dos danos decorrentes do rompimento da barragem de Fundão.
                </p>

                <p style="text-align: center;">
                  <strong
                    >Autoria: Equipe de Assessoria Jurídica da ATI de
                    Mariana</strong
                  >
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container card__descumprimento mt-5">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" style="border-bottom: 2px solid #AACF4F">
            <p
              style="text-align: center; font-size: 1.5em; margin-bottom: 0; font-weight: bold;"
            >
              Descumprimento de prazos pela Fundação Renova
            </p>
            <p
              style="background-color: #AACF4F;font-size: 20px; text-align: center; padding: 0.1em;"
            >
              Prazos estabelecidos no TTAC são descumpridos de forma reterada
              pela entidade.
            </p>
          </v-col>
        </v-row>

        <v-row
          class="d-flex justify-center align-center"
          style="border-bottom: 2px solid #AACF4F"
        >
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-center align-center flex-column"
          >
            <div class="d-flex align-center justify-center">
              <div
                style="width: 24px; height: 24px; background-color: #AACF4F"
              ></div>
              <p style="margin-bottom: 0; font-weight: bold;" class="ml-2">
                Prazo de 3 meses
              </p>
            </div>
            <div class="d-flex justify-center align-center">
              <p style="font-size: 14px; text-align: center; max-width: 80%">
                Este prazo se refere ao lapso temporal que a Fundação Renova
                possui para apresentar proposta de indenização às famílias
                atingidas, a contar da data de envio do dossiê para a entidade.
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-center align-center flex-column"
          >
            <div class="d-flex align-center justify-center">
              <div
                style="width: 24px; height: 24px; background-color: #AACF4F"
              ></div>
              <p style="margin-bottom: 0; font-weight: bold;" class="ml-2">
                Prazo de 1 ano
              </p>
            </div>
            <div class="d-flex justify-center align-center">
              <p style="font-size: 14px; text-align: center; max-width: 80%">
                O prazo de 1 ano, da mesma forma, se inicia a partir de do
                dossiê das famílias à Fundação Renova e se refere ao prazo que a
                entidade tem para finalizar as negociações na FNE.
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row
          class="d-flex justify-space-around align-center mt-5"
          style="border-bottom: 2px solid #AACF4F"
        >
          <v-col
            cols="12"
            md="5"
            class="d-flex flex-column justify-center align-center mt-5 mb-5 align-self-stretch"
            style="background-color: #AACF4F"
          >
            <img width="30%" src="./assets/card_01.png" alt="" />

            <p
              class="mt-3"
              style="font-weight: bold; font-size: 20px; text-align: center"
            >
              Descumprimento prazo de 3 meses
            </p>
            <p style="font-size: 18px; text-align: center;">
              Quase 45% das famílias acompanhadas pela Assessoria Jurídica
              receberam proposta de indenização somente após o transcurso do
              prazo de 3 meses.
            </p>
          </v-col>

          <v-col
            cols="12"
            md="5"
            class="d-flex flex-column justify-center align-center mt-5 mb-5 align-self-stretch"
            style="background-color: #AACF4F"
          >
            <img width="30%" src="./assets/card_02.png" alt="" />
            <p
              class="mt-3"
              style="font-weight: bold; font-size: 20px; text-align: center"
            >
              Descumprimento prazo de 1 ano
            </p>
            <p style="font-size: 18px; text-align: center;">
              Em 16% das negociações acompanhadas pela Assessoria Jurídica até
              fevereiro de 2022, houve o descumprimento de 1 ano previsto no
              TTAC.
            </p>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center align-center flex-column">
          <v-col class="d-flex justify-center align-center">
            <div class="d-flex align-center justify-center flex-column">
              <div class="d-flex justify-center align-center mb-3">
                <div
                  style="width: 24px; height: 24px; background-color: #AACF4F"
                ></div>
                <p
                  style="margin-bottom: 0; font-weight: bold; font-size: 24px;"
                  class="ml-2"
                >
                  Propostas incompletas apresentadas pela FR
                </p>
              </div>

              <p style="text-align: center; font-size: 16px;  max-width: 70%">
                É importante destacar que independente do cumprimento ou não do
                prazo de 3 meses pela Fundação Renova, em relação aos casos
                acompanhados pela Assessoria Jurídica, em sua grande maioria, às
                famílias recebem propostas imcompletas, o que enseja
                apontamentos feitos pelos atingidos e nova análise dos casos por
                parte da Fundação Renova.
              </p>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="5"
            class="d-flex justify-center align-center flex-column"
            style="background-color: #AACF4F; padding: 1em;"
          >
            <img class="mb-3" width="30%" src="./assets/card_03.png" alt="" />
            <p style="font-size: 16px; text-align: center; margin-bottom: 0;">
              Quase 80% das famílias acompanhadas pela AJ receberam propostas
              imcompletas da Fundação Renova.
            </p>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 section__mb">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p style="margin-bottom: 0;">
                <sup>1</sup> Disponível em:
                <a
                  target="_blank"
                  class="pink--text"
                  style="text-decoration: none;"
                  href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                >
                  http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf</a
                >. Acesso em 17/03/2022.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="section__mb">
        <v-row class="d-flex justify-center align-center pa-4 section__mb">
          <v-col cols="12" md="7" class="d-flex justify-center align-center">
            <v-btn
              :to="{
                name: 'conteudos_tematicos_violacoes_parcialidade_mediacao',
              }"
              color="#EDBD43"
            >
              <strong>Conhecer "Parcialidade da mediação""</strong></v-btn
            >
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito /",
          href: "conteudos_tematicos_violacoes_direitos",
        },

        {
          text: "Descumprimento de prazos",
          href: "conteudos_tematicos_descumprimento_prazos",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.card__descumprimento {
  background-color: #f9f7ed;
  padding: 2em;
  max-width: 978px;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

h3 {
  color: white;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }
  h3 {
    color: black;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
